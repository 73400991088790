.react-date-picker .react-date-picker__wrapper,
.react-time-picker .react-time-picker__wrapper {
  flex-direction: row;
  align-items: center;
  padding: 10px;

  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #000000;

  border: 1px solid #9d9ca9;
}

.react-date-picker span .react-date-picker__calendar--open {
  display: block;
}

.react-date-picker span .react-date-picker__calendar .react-calendar {
  display: block;

  border: 1px solid #9d9ca9;
}

.react-date-picker
  span
  .react-date-picker__calendar
  .react-calendar
  .react-calendar__navigation
  .react-calendar__navigation__label__labelText {
  font-family: Nunito;
}

.react-date-picker
  span
  .react-date-picker__calendar
  .react-calendar
  .react-calendar__viewContainer
  .react-calendar__month-view
  div {
  display: block;
}

.react-date-picker__inputGroup,
.react-time-picker__inputGroup {
  align-items: baseline;
}
.react-time-picker__inputGroup__divider {
  font-size: 24px !important;
  line-height: 16px !important;
}