body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto, sans-serif';
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

#root {
  flex: 1;
  z-index: 1;
}

div {
  display: inherit;
  flex-direction: inherit;
  z-index: inherit;

  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

p {
  margin: 0;
}

/* remove outline on focus by click */
.no-outline-on-focus:focus {
  outline: none;
}

/* Changement du style de l'autofill */
input:-webkit-autofill {
  transition-delay: 9999s;
}

#portal_root,
#toast_root,
#alert_root {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

@font-face {
  font-family: 'Georgia';
  src: url(./assets/fonts/Georgia.ttf);
  font-weight: 300, 400, normal;
}

@font-face {
  font-family: 'Georgia Bold';
  src: url(./assets/fonts/Georgia-Bold.ttf);
  font-weight: 600, 700, 800, bold;
}

@font-face {
  font-family: 'Loreal Essentielle';
  src: url(./assets/fonts/LOREALEssentielle-Regular.otf);
  font-weight: 300, 400, normal;
}

@font-face {
  font-family: 'Loreal Essentielle Bold';
  src: url(./assets/fonts/LOREALEssentielle-Bold.otf);
  font-weight: 600, 700, 800, 900, bold;
}
